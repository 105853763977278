import React from 'react';
import translit from 'cyrillic-to-translit-js';
import Translate from 'components/knowledge-base/Translate/Translate';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import CollapseBlock from 'components/CollapseBlock';
import withWindowWidth from 'helpers/withWindowWidth';
import * as style from './quickNav.module.css';

class QuickNav extends React.Component {
  constructor(props) {
    super(props)

    this.buildNav = this.buildNav.bind(this)
    this.state = {
      navContent: null,
    }
  }

  buildNav() {
    const content = document.createElement('div')
    content.innerHTML = this.props.html
    const headings = content.querySelectorAll('h1, h2, h3, h4, h5, h6')
    let ToC = ``,
      level,
      baseLevel

    let min_heading = 6
    headings.forEach(heading => {
      min_heading = Math.min(min_heading, parseInt(heading.tagName.substr(1)))
    })
    headings.forEach(heading => {
      let newLine
      const title = heading.textContent
      let id = heading.id || heading.textContent;
      id = translit().transform(id, '-')
      const link = `#${id}`
      const li = `<li class="toc-entry toc-${heading.tagName.toLowerCase()} ${style.item}">`

      let prevLevel = level || min_heading
      level = heading.tagName.substr(1)
      baseLevel = baseLevel || level

      if (`${prevLevel}` === `0` && `${level}` === `${min_heading}`) {
        newLine = `${li}`
      } else if (`${level}` === `${prevLevel}`) {
        newLine = `</li>${li}`
      } else if (level > prevLevel) {
        newLine = `<ul>${li}`.repeat(level - prevLevel)
      } else if (level < prevLevel) {
        newLine = `</li></ul>`.repeat(prevLevel - level) + `</li>${li}`
      }
      newLine += `<a href="${link}">${title}</a>`
      ToC += newLine
    })

    ToC += `</li></ul>`.repeat(Math.max(1, level - baseLevel))
    ToC += `</li>`
    return ToC
  }

  componentDidMount() {
    const { width } = this.props;
    const navContent = this.buildNav()
    this.setState({
      navContent: navContent,
    });

    if (typeof window !== 'undefined') {
      if (width >= 768) {
        window.addEventListener('scroll', this.scrollHandler);
        this.scrollHandler();
        this.ps = new PerfectScrollbar('#quick-panel');
      }
    }
  }

  componentDidUpdate() {
    const { width } = this.props;
    if (typeof window !== 'undefined') {
      if (width < 768) {
        window.removeEventListener('scroll', this.scrollHandler);
        this.ps = null;
      }
      if (width >= 768) {
        if (!this.ps) {
          window.addEventListener('scroll', this.scrollHandler);
          this.ps = new PerfectScrollbar('#quick-panel');
          this.scrollHandler();
          this.ps.update();
        }
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.scrollHandler);
    }
  }

  scrollHandler = () => {
    const panel = document.getElementById('quick-panel');
    const rect = panel.getBoundingClientRect();
    const parentRect = panel.parentElement.getBoundingClientRect();
    const top = parentRect.top + document.documentElement.scrollTop;
    const height = rect.height;

    if (window.scrollY > top && window.scrollY <= top + parentRect.height - height) {
      panel.classList.remove(style.absolutePanel);
      panel.classList.add(style.fixedPanel);
    } else if (window.scrollY > top + parentRect.height - height) {
      panel.classList.remove(style.fixedPanel);
      panel.classList.add(style.absolutePanel);
    } else {
      panel.classList.remove(style.absolutePanel);
      panel.classList.remove(style.fixedPanel);
    }
  }

  render() {
    const { lang, width } = this.props;

    if (width >= 768) {
      return (
        <div id="quick-panel" className={style.root}>
          <div className="panel panel-default">
            <div className="panel-body">
              <p className={style.title}>
                <Translate id="Quick Nav" lang={lang} />
              </p>
              <hr />
              <ul
                className="panel-nav list-unstyled"
                dangerouslySetInnerHTML={{ __html: this.state.navContent }}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={style.root}>
        <CollapseBlock
          title={(
            <Translate id="Quick Nav" lang={lang} />
          )}
          titleClass={style.title}
          withoutContainerStyle
          withDivider
        >
          <div>
            <ul
              dangerouslySetInnerHTML={{ __html: this.state.navContent }}
            />
          </div>
        </CollapseBlock>
      </div>
    )
  }
}

export default withWindowWidth(QuickNav);

