// extracted by mini-css-extract-plugin
export var root = "KnowledgeBaseItem-module--root--qO3o0";
export var header = "KnowledgeBaseItem-module--header--AcGZs";
export var top = "KnowledgeBaseItem-module--top--01Q--";
export var search = "KnowledgeBaseItem-module--search--JghST";
export var langSwitch = "KnowledgeBaseItem-module--langSwitch--IQrDK";
export var title = "KnowledgeBaseItem-module--title--adIju";
export var wrap = "KnowledgeBaseItem-module--wrap--a-CjJ";
export var text = "KnowledgeBaseItem-module--text--qgmo2";
export var quicknav = "KnowledgeBaseItem-module--quicknav--4u1C7";
export var blockquote = "KnowledgeBaseItem-module--blockquote--EWQpl";