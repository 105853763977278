import { Component } from 'react';
import translations from 'components/knowledge-base/Translate/kb-translations.json';

export function t(id, lang) {
  if (translations[lang] && translations[lang][id]) {
    return translations[lang][id];
  }

  return id;
}

class Translate extends Component {
  render() {
    const { id, lang } = this.props;

    return t(id, lang);
  }
}

export default Translate;
