import React from 'react';

const withWindowWidth = Component => {
  class WrappedComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        width: this.findSize()
      };
    }

    componentDidMount() {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.resizeUpdate);
      }
    }

    componentWillUnmount() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.resizeUpdate);
      }
    }

    findSize = () => {
      if (typeof window === 'undefined') {
        return null;
      }
      return window.innerWidth;
    }

    resizeUpdate = () => {
      const currentSize = this.findSize();

      this.setState({
        width: currentSize
      });
    }

    render() {
      return <Component width={this.state.width} {...this.props} />;
    }
  }

  return WrappedComponent;
};

export default withWindowWidth;
