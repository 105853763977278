import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowImg from 'utils/images/arrow-down2.svg';
import arrowImgOrange from 'utils/images/arrow-down2-orange.svg';
import cx from 'classnames';
import * as s from './CollapseBlock.module.css';

const CollapseBlock = (props) => {
  const {
    children,
    styles = {},
    title,
    rootClass = '',
    openRootClass = '',
    titleClass = '',
    openTitleClass = '',
    forceOpen = false,
    isDefaultOpen = false,
    withDivider = false,
  } = props;

  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(() => isDefaultOpen);
  const contentRef = useRef(null);
  const isCollapseOpen = forceOpen ? true : isOpen;

  const containerStyle = {
    height:  `${isCollapseOpen ? height : 0}px`,
  };

  useLayoutEffect(() => {
    if (contentRef && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  const toggleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(s.root, rootClass, {
      [openRootClass]: isOpen,
    })} style={{ ...styles }}>
      <div
        className={s.title}
        onClick={toggleOpen}
        role="presentation"
      >
        <div className={cx(s.titleText)}>
          <div className={cx(titleClass, {
            [openTitleClass]: isOpen,
          })}>
            {title}

            {
              forceOpen ? null : (
                <div className={cx(s.arrow, {
                  [s.isOpen]: isCollapseOpen,
                  [s.withDividerArrow]: withDivider,
                })}>
                  <img src={isOpen ? arrowImgOrange : arrowImg} alt="arrow" width="32" />
                </div>
              )
            }
          </div>
          {withDivider && <hr />}
        </div>
      </div>

      <div
        ref={contentRef}
        className={s.container}
        style={forceOpen ? {} : containerStyle}
      >
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};

CollapseBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  rootClass: PropTypes.string,
  openRootClass: PropTypes.string,
  titleClass: PropTypes.string,
  openTitleClass: PropTypes.string,
};

export default CollapseBlock;
