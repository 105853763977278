import React, { Component } from 'react';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import _split from 'lodash/split';
import _slice from 'lodash/slice';
import htmlToText from 'html-to-text';
import translit from 'cyrillic-to-translit-js';
import Layout from 'components/layout';
import Bottom from 'components/Bottom/Bottom';
import Translate from 'components/knowledge-base/Translate/Translate';
import QuickNav from 'components/knowledge-base/QuickNav/QuickNav';
import SearchResults from 'components/knowledge-base/SearchResults';
import Breadcrumbs from 'components/knowledge-base/Breadcrumbs/Breadcrumbs';
import { getExcludedLangs } from 'helpers/language';
import {
  KB_OG_IMG,
  KB_OG_IMG_RU,
} from 'constants/index'
import * as s from './KnowledgeBaseItem.module.css';

const DESCRIPTIONS_WORDS_COUNT = 25;
const DESCRIPTIONS_MAX_LENGTH = 320;

class KnowledgeBaseItem extends Component {
  updateMarkdownContent(html) {
    const calloutStartRE = /{% callout( [a-zA-Z]*)? %}/g
    const calloutEndRE = /{% endcallout %}/g
    const rawStartRE = /{%( |)raw( |)%}/g
    const rawEndRE = /{%( |)endraw( |)%}/g
    const linkRE = /({% |%7B%25%20)link( |%20)([a-zA-Z-_/.]*)?( %}|%20%25%7D)/g
    let matched
    while ((matched = calloutStartRE.exec(html)) !== null) {
      const type = (matched[1] && matched[1].trim()) || 'primary'
      html = html.replace(
        matched[0],
        `<div class="bs-callout bs-callout-${type}">`
      )
    }
    html = html.replace(calloutEndRE, '</div>')
    while ((matched = linkRE.exec(html)) !== null) {
      let link = matched[3] && matched[3].trim()
      if (link) {
        link = link.replace('_kb', '/help').replace('.md', '')
        html = html.replace(matched[0], link)
      }
    }
    html = html.replace(rawStartRE, '<pre>').replace(rawEndRE, '</pre>')

    return html;
  }

  componentWillMount() {
    const html = _get(this.props, 'data.strapiKnowledgeBaseArticle.content.data.childMarkdownRemark.html', '');
    this.setState({
      htmlContent: html,
    })
  }

  getMeta = currentLang => {
    const title = _get(this.props, 'pageContext.data.meta_title', '') || _get(this.props, 'pageContext.data.title', '');
    const html = _get(this.props, 'data.strapiKnowledgeBaseArticle.content.data.childMarkdownRemark.html');
    let descFromText = htmlToText.fromString(html);
    descFromText = _slice(_split(descFromText, ' '), 0, DESCRIPTIONS_WORDS_COUNT).join(' ');
    descFromText = descFromText.substr(0, DESCRIPTIONS_MAX_LENGTH);

    const metaDescription = _get(
      this.props,
      'pageContext.data.meta_description',
    );

    const img = currentLang === 'ru' ? KB_OG_IMG_RU : KB_OG_IMG;

    return {
      title,
      meta: [
        { name: 'description', content: metaDescription || descFromText },
        { property: 'og:title', content: title },
        { property: 'og:image', content: img },
        { property: 'og:description', content: metaDescription || descFromText },
        { property: 'twitter:image', content: img },
      ]
    }
  }

  render() {
    const { htmlContent } = this.state;
    const data = _get(this.props, 'pageContext.data', {});
    const rootPathes = _get(this.props, 'pageContext.rootPathes', null);
    const currentLang = _get(this.props, 'pageContext.currentLang', 'en');
    const langs = _get(this.props, 'pageContext.langs', []);

    const headings = () => {
      if(typeof document !== 'undefined' && document) {
        const content = document.createElement('div');
        content.innerHTML = _get(this.props, 'data.strapiKnowledgeBaseArticle.content.data.childMarkdownRemark.html', '');
        return content.querySelectorAll('h1, h2, h3, h4, h5, h6');
      }
      return [];
    }

    const {
      title,
      parent_category,
    } = data;

    const rootPath = _get(rootPathes, currentLang, '/');
    const breadcrumbs = [{
      title: <Translate id="Knowledge Base" lang={currentLang} />,
      path: rootPath,
    }];

    if (parent_category) {
      breadcrumbs.push({
        title: <Translate id={data.parent_category} lang={currentLang} />,
        path: data.parent_category_path,
      });
    }

    return (
      <Layout
        customMeta={this.getMeta(currentLang)}
        pageName={_get(this.props, 'location.pathname')}
        lang={currentLang}
        langs={langs}
        excludedLangs={getExcludedLangs(currentLang)}
        registrationURL={_get(this.props, 'pageContext.registrationURL', '')}
      >
        <div className={s.root}>
          <div className={s.breadcrumbs}>
            <Breadcrumbs data={breadcrumbs} />
          </div>
          <SearchResults>
            <div>
              <div className={s.header}>
                <h1 className={s.title}>
                  {title}
                </h1>
              </div>
              <div className={s.wrap}>
                <div className={s.text}>
                  <div
                    id="pageContent"
                    dangerouslySetInnerHTML={{
                      __html: _get(this.props, 'data.strapiKnowledgeBaseArticle.content.data.childMarkdownRemark.html', '')
                    }}
                  />
                </div>
                {headings().length > 0 && (
                  <div className={s.quicknav}>
                    <QuickNav html={htmlContent} lang={currentLang} />
                  </div>
                )}
              </div>
            </div>
          </SearchResults>
        </div>
        <Bottom/>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!, $locale: String!) {
    strapiKnowledgeBaseArticle(slug:{ eq: $slug }, locale: {eq: $locale}) {
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default KnowledgeBaseItem;
