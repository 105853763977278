import React, { Component } from 'react';
import cx from 'classnames';
import Search from 'components/Search';
import * as s from './SearchResults.module.css';

const POPULAR_SEARCH = [
  'ASIC',
  'Ethereum',
  'AMD',
  'NVIDIA',
  'GPU',
  'ASIC Hub',
  'Start mining',
  'Security',
];

class SearchResults extends Component {
  state = {
    isSearching: false,
  };

  setSearching = isSearching => {
    if(isSearching !== this.state.isSearching) {
      this.setState({ isSearching: !!isSearching });
    }
  }

  render() {
    const { isSearching } = this.state;
    const { children } = this.props;

    return (
      <div className={s.root}>
        <div className={s.top}>
          <div className={s.search}>
            <Search
              indices={[{ name: `KnowledgeBase`, title: `KnowledgeBase` }]}
              setSearching={this.setSearching}
              isSearching={isSearching}
              popular={POPULAR_SEARCH}
            />
          </div>
        </div>
        <div className={cx({
          [s.none]: isSearching,
          [s.block]: !isSearching
        })}>
          {children}
        </div>
      </div>
    )
  }
}

export default SearchResults;
